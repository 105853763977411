import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import { Container } from "components/misc/Layouts";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/ThreeColWithSideImage";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Portfolio from "components/cards/ThreeColSlider";
import Footer from "components/footers/MiniCenteredFooter.js";

const SectionContainer = tw(Container)``;
function App() {
  return (
    <AnimationRevealPage>
      <Hero />
      <SectionContainer id="main">
        <MainFeature />
      </SectionContainer>
      <SectionContainer id="services">
        <Features />
      </SectionContainer>
      <SectionContainer id="portfolio">
        <Portfolio />
      </SectionContainer>
      {/* <ContactUsForm /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
export default App;
